<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useLinks } from '~/composables';

const { t } = useI18n();
const { opalePublicAppUrl } = useLinks();

declare global {
  interface Window {
    _hsp: [unknown];
  }
}
const links = [
  {
    label: t('_app.footer.legal.legalInformation'),
    href: `${opalePublicAppUrl()}/mention-legales-et-cgu`,
    target: '_blank'
  },
  {
    label: t('_app.footer.legal.regulatoryNotices'),
    href: `${opalePublicAppUrl()}/mentions-reglementaires`,
    target: '_blank'
  },
  {
    label: t('_app.footer.legal.privacyPolicy'),
    href: `${opalePublicAppUrl()}/politique-de-confidentialite`,
    target: '_blank'
  },
  {
    label: 'contact@opalecapital.com',
    href: 'mailto:contact@opalecapital.com',
    target: '_blank'
  },
];

const showBanner = () => {
  const _hsp = window._hsp = window._hsp || [];
  _hsp.push(['showBanner']);

};
</script>

<template>
  <!-- Temporaire -->
  <button
    id="hs-hud-cookie-settings"
    class="d-none d-md-block"
    @click="showBanner"
  >
    Cookie Settings
  </button>
  <o-minimalist-footer
    class="d-none d-md-block"
    color="background"
    :links="links"
  />
</template>
<style>
  button#hs-hud-cookie-settings {
    position: absolute !important;
    right: -40px;
    bottom: 111px;
    padding: 5px;
    font-size: 12px;
    color: white;
    appearance: none;
    background-color: #425b76;
    border-width: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: rotate(-90deg);
  }
</style>
