// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'vuetify/styles';
import { OAppBar, OHelperCard, OMarkdown, OStepper } from '@opalecapital/ui/components';
import { OFundBanner, OFundDueDateCard } from '@opalecapital/ui/fund/components';
import { createOpaleComponents } from '@opalecapital/ui-components';
import { createOpaleForm } from '@opalecapital/ui-form';
import { defaults, icons, themes } from '@opalecapital/ui-themes';
import { createVuetify } from 'vuetify';
import * as vuetifyComponents from 'vuetify/components';
import * as directives from 'vuetify/directives';

const theme = {
  defaultTheme: 'opale-light',
  themes
};

const components = {
  ...vuetifyComponents,
  OAppBar, OMarkdown, OHelperCard, OStepper, OFundBanner, OFundDueDateCard
};

export const opaleComponents = createOpaleComponents();
export const opaleForm = createOpaleForm();
export const vuetify = createVuetify({ components, defaults, directives, icons, theme });
