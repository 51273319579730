import '~/styles/main.scss';
import { createApp, defineAsyncComponent } from 'vue';
import App from '~/App.vue';
import {
  head,
  i18n,
  opaleComponents,
  opaleForm,
  persistedPinia,
  vuetify
} from '~/modules';
import { Dialog } from '~/stores/dialog';
import {
  initializeSentry,
  opaleApi
} from '~/utils';
import { router } from './router/router';

const app = createApp(App);

app.use(head)
  .use(i18n)
  .use(opaleApi)
  .use(persistedPinia)
  .use(router)
  .use(opaleForm)
  .use(opaleComponents)
  .use(vuetify);

// handles dialogs
// @TODO: challenge this, maybe remove the custom dialogs logic
Object.keys(Dialog).forEach(dialogKey => {
  const component = defineAsyncComponent(() => import(`~/dialogs/${dialogKey}.vue`));
  app.component(dialogKey + 'Dialog', component);
});

initializeSentry(app, router);

app.mount('#app');
