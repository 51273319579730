import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { LocationAsRelativeRaw } from 'vue-router';
import { useAuth } from '~/stores';

export interface MenuItem {
  text: string,
  to: LocationAsRelativeRaw,
  variant?: 'text' | 'flat' | 'outlined' | 'plain'
  icon?: string
}

export const useItems = () => {
  const { t } = useI18n();
  const authStore = useAuth();

  const userItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    {
      text: t('_app.nav.userItems.settings'),
      to: { name: 'settings' },
      icon: 'fa-gear'
    },
    {
      text: t('_app.nav.userItems.logout'),
      to: { name: 'logout' },
      icon: 'fa-right-from-bracket'
    },
  ] : []);

  const actionItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    { text: t('_app.nav.actionItems.dashboard'),        to: { name: 'dashboard'         }, icon: 'fa-chart-pie' },
    { text: t('_app.nav.actionItems.funds'),            to: { name: 'funds'             }, icon: 'fa-shapes' },
    { text: t('_app.nav.actionItems.investorProfiles'), to: { name: 'investor-profiles' }, icon: 'fa-user' },
    { text: t('_app.nav.actionItems.subscriptions'),    to: { name: 'subscriptions'     }, icon: 'fa-pen-to-square' },
    { text: t('_app.nav.actionItems.fundReports'),      to: { name: 'documents'         }, icon: 'fa-file-arrow-down' },
  ] : []);

  /* eslint-disable max-len */
  const mobileActionItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    { text: t('_app.nav.mobileActionItems.dashboard'),        to: { name: 'dashboard'         }, icon: 'fa-chart-pie' },
    { text: t('_app.nav.mobileActionItems.funds'),            to: { name: 'funds'             }, icon: 'fa-shapes' },
    { text: t('_app.nav.mobileActionItems.investorProfiles'), to: { name: 'investor-profiles' }, icon: 'fa-user' },
    { text: t('_app.nav.mobileActionItems.subscriptions'),    to: { name: 'subscriptions'     }, icon: 'fa-pen-to-square' },
    { text: t('_app.nav.mobileActionItems.fundReports'),      to: { name: 'documents'         }, icon: 'fa-file-arrow-down' },
  ] : []);
  /* eslint-enable max-len */

  return { actionItems, mobileActionItems, userItems };
};
