import type { NumberOptions } from 'vue-i18n';
import * as fr from './fr';

const defaultNumberFormat: Record<string, NumberOptions> = {
  currency: {
    style: 'currency' as const,
    currency: 'EUR',
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  },
  multiple: {
    style: 'decimal' as const,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    minimumSignificantDigits: 2,
  },
  percent: {
    style: 'percent' as const,
    useGrouping: true,
  },
};

export const messages = {
  fr,
};

export const numberFormats = {
  'en-GB': { ...defaultNumberFormat },
  fr: { ...defaultNumberFormat },
};
